<script lang="ts">
    import { twMerge } from 'tailwind-merge';
    import Frame from '../utils/Frame.svelte';
    import { createEventDispatcher } from 'svelte';
    import focusTrap from '../utils/focusTrap';
    import type { SizeType } from '../types';
    import type { ModalPlacementType } from '../types';
    import { fade, fly } from 'svelte/transition';

    export let open = false;
    export let size: SizeType = 'md';
    export let placement: ModalPlacementType = 'center';
    export let outsideclose = true;
    export let autoclose = true;
    export let permanent = false;
    export let backdropClass = 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80';
    export let defaultClass = 'relative flex flex-col mx-auto max-h-full';

    export let hasBorderHeader: boolean | undefined = undefined;
    export let hasBorderFooter: boolean | undefined = undefined;

    const dispatch = createEventDispatcher();

    $: if (open) dispatch('open');

    function prepareFocus(node: HTMLElement) {
        const walker = document.createTreeWalker(node, NodeFilter.SHOW_ELEMENT);
        let n: Node | null;
        while ((n = walker.nextNode())) {
            if (n instanceof HTMLElement) {
                const el = n as HTMLElement;
                const [x, y] = isScrollable(el);
                if (x || y) el.tabIndex = 0;
            }
        }
        node.focus();
    }

    const getPlacementClasses = () => {
        switch (placement) {
            // top
            case 'top-left':
                return ['justify-start', 'items-start'];
            case 'top-center':
                return ['justify-center', 'items-start'];
            case 'top-right':
                return ['justify-end', 'items-start'];

            // center
            case 'center-left':
                return ['justify-start', 'items-center'];
            case 'center':
                return ['justify-center', 'items-center'];
            case 'center-right':
                return ['justify-end', 'items-center'];

            // bottom
            case 'bottom-left':
                return ['justify-start', 'items-end'];
            case 'bottom-center':
                return ['justify-center', 'items-end'];
            case 'bottom-right':
                return ['justify-end', 'items-end'];

            default:
                return ['justify-center', 'items-center'];
        }
    };

    const sizes = {
        xs: 'max-w-sm',
        sm: 'max-w-lg',
        md: 'max-w-2xl',
        lg: 'max-w-4xl',
        xl: 'max-w-7xl',
    };

    const onAutoClose = (e: MouseEvent) => {
        const target: Element = e.target as Element;
        if (autoclose && target?.tagName === 'BUTTON') close(e, true); // close on any button click
        if (outsideclose && target === e.currentTarget) close(e); // close on click outside
    };

    const close = (e: Event, skipDispatch = false) => {
        e.preventDefault();
        open = false;
        if (!skipDispatch) dispatch('close');
    };

    let frameClass: string;
    $: frameClass = twMerge(defaultClass, 'w-full', $$props.class);

    const isScrollable = (e: HTMLElement): boolean[] => [
        e.scrollWidth > e.clientWidth && ['scroll', 'auto'].indexOf(getComputedStyle(e).overflowX) >= 0,
        e.scrollHeight > e.clientHeight && ['scroll', 'auto'].indexOf(getComputedStyle(e).overflowY) >= 0,
    ];

    let backdropCls: string = twMerge(backdropClass, $$props.classBackdrop);

    function handleKeys(e: KeyboardEvent) {
        if (e.key === 'Escape' && !permanent) return close(e);
    }

    export let maxHeight = '41rem'; // 656px

    export let zIndexBackdrop = 40;
    export let zIndexModal = 45;

    // 디자인에서 모달의 최대 높이는 656px입니다. 하지만 스크롤 되는 영역은 모달의 body영역입니다.
    // 이것을 계산하기 위해 다음과 같이 body영역에 max-height를 지정해줍니다.
    //
    // <div
    //     class="flex-1 space-y-2 px-6 {$$slots.header ? 'py-6' : 'mt-10 py-10'} overflow-y-auto overscroll-contain"
    //     role="document"
    //     style="max-height: calc(100% - {hasBorderHeader ? 4.75 : 2.5}rem - {hasBorderFooter ? 6.0625 : 4.5}rem);"
    //     ...
    // >
    //     ...
    // </div>
    //
    // 상단부분의 크기는 상단에 구분선이 있을 때, 4.75rem, 없을 때 2.5rem입니다.
    // 하단부분의 크기는 하단에 구분선이 있을 때, 6.0625rem, 없을 때 4.5rem입니다.
</script>

{#if open}
    <!-- backdrop -->
    <div class={twMerge('fixed inset-0', backdropCls)} style="z-index: {zIndexBackdrop};" transition:fade|global on:outroend={() => dispatch('outroend')} />
    <!-- dialog -->
    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
    <div
        use:prepareFocus
        use:focusTrap
        on:keydown={handleKeys}
        on:wheel|preventDefault|nonpassive
        on:click={onAutoClose}
        class={twMerge('h-modal fixed left-0 right-0 top-0 flex h-full w-full p-4 md:inset-0', ...getPlacementClasses())}
        style="z-index: {zIndexModal};"
        tabindex="-1"
        aria-modal="true"
        role="dialog"
    >
        <div class="relative flex {sizes[size]} w-full" transition:fly|global={{ y: 50 }} style="max-height: min(100%, {maxHeight})">
            <!-- Modal content -->
            <Frame rounded shadow {...$$restProps} class={frameClass}>
                {#if !permanent}
                    <button on:click={close} type="button">
                        <svg
                            class="absolute {hasBorderHeader ?? $$slots.header ? 'right-6 top-6' : 'right-4 top-4'} m-0"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M5 5.5L19 19.5M5 19.5L19 5.5L5 19.5Z" stroke="#D1D5DB" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                {/if}
                <slot>
                    <!-- Modal header -->
                    {#if $$slots.header}
                        <Frame color={$$restProps.color} class="flex items-center justify-between rounded-t-lg border-t border-gray-200 p-6">
                            <h2 class="text-lg font-semibold {$$restProps.color ? '' : 'text-gray-900 dark:text-white'} p-0">
                                <slot name="header" />
                            </h2>
                        </Frame>
                    {/if}
                    <!-- Modal body -->
                    <div
                        class="flex-1 space-y-2 px-6 {$$slots.header ? 'py-6' : 'mt-10 py-10'} overflow-y-auto overscroll-contain"
                        role="document"
                        on:keydown|stopPropagation={handleKeys}
                        on:wheel|stopPropagation|passive
                    >
                        <slot name="body" />
                    </div>
                    <!-- Modal footer -->
                    {#if $$slots.footer}
                        <Frame color={$$restProps.color} class="flex items-center justify-end space-x-2 rounded-b-lg p-6 {hasBorderFooter ?? $$slots.header ? 'border-t border-gray-200' : 'pt-0'}">
                            <slot name="footer" />
                        </Frame>
                    {/if}
                </slot>
            </Frame>
        </div>
    </div>
{/if}

<!--
@component
## Features
    [Go to Modal](https://flowbite-svelte.com/docs/components/modal)
    - Setup
    - Default modal
    - Closing by clicking outside
    - Pop-up modal
    - Form element
    - Crypto wallet
    - Sizes
    - Placement
    - Colors
    - Scrolling behaviour
    ## Props
    @prop open: boolean = false;
    @prop size: SizeType = 'md';
    @prop placement: ModalPlacementType = 'center';
    @prop autoclose: boolean = true;
    @prop outsideclose: boolean = true; 
    @prop permanent: boolean = false;
    @prop backdropClass: string = 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80';
    @prop defaultClass: string = 'relative flex flex-col mx-auto';
    @prop hasBorderHeader: boolean = false;
    @prop hasBorderFooter: boolean = false;
    @prop maxHeight: string = '41rem';
## Example
    ```
    <script>
        import { Button, Modal } from '$component/basic'
        let defaultModal = false;
    </script>

    <Button on:click={() => defaultModal = true}>Default modal</Button>
    <Modal id="default-modal" bind:open={defaultModal}>
        <svelte:fragment slot="header">Terms of Service</svelte:fragment>
        <svelte:fragment slot="body">
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
            </p>
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
            </p>
        </svelte:fragment>
        <svelte:fragment slot='footer'>
            <Button on:click={() => alert('Handle "success"')}>I accept</Button>
            <Button color="alternative">Decline</Button>
        </svelte:fragment>
    </Modal>
    ```
### Example(functional)
    ```
    <script>
        import Alert from '$components/modalContent/Alert.svelte';
        async function openModal() {
            const id = await modal.open(Loader, {});
            console.log('Modal closed', id);
            const id2 = await modal.open(Alert, { msg: '안녕하십니까?' }, { outsideclose: false, size: 'xs' });
            console.log('Modal closed', id2);
            const id3 = await modal.open(Alert, { msg: '헤더에 X가 없는 얼럿입니다' }, { outsideclose: false, permanent: true });
            console.log('Modal closed', id3);
        }
        openModal();
    </script>
    ```
-->
