<script>
    import { SvelteToast } from '@zerodevx/svelte-toast';
    // Optionally set default options here
    export let options = {
        theme: {
            '--toastWidth': '300px',
            '--toastBackground': 'transparent',
            '--toastMargin': '0.5rem 0 0 0',
            '--toastProgressBackground': '#D1D5DB',
            '--toastBarHeight': '4px',
            '--toastBarBottom': '1px',
            '--toastPadding': 0,
            '--toastMsgPadding': 0,
            '--toastColor': 'currentColor',
            '--toastBoxShadow': 'none',
            '--toastBorderRadius': '4px',
        },
    };
</script>

<SvelteToast {options} />
