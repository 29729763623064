<script lang="ts">
    import '$src/app.postcss';
    import { onMount, onDestroy, afterUpdate } from 'svelte';
    import Loader from '$components/common/Loader.svelte';
    import { browser, building } from '$app/environment';
    import { beforeNavigate, afterNavigate } from '$app/navigation';
    import { fade } from 'svelte/transition';
    import { cubicIn, cubicOut } from 'svelte/easing';
    import { modal } from '$stores/modalStore';
    import { Modal, FullModal } from '$components/basic';
    import { pwaInfo } from 'virtual:pwa-info';
    import ResizeObserver from 'resize-observer-polyfill';
    import Toast from '$components/toasts/Toast.svelte';
    import { Tiptap } from '$components/tiptap'; // 황당하게도 vite dev 시에 초기에 로딩되지않고 페이지 로딩후에 tiptap이 import되면 무한 hang에 빠지는 이상한 현상이 발생함. vite bug로 추정
    if (browser) window.ResizeObserver = ResizeObserver; // Polyfill

    let loaded = false; // 페이지 첫 로딩시 flashing 현상 감추기
    let ReloadPrompt: any; // PWA 리로드를 체크하는 프롬프트
    onMount(async () => {
        loaded = true;
        pwaInfo && (ReloadPrompt = (await import('$components/pwa/ReloadPrompt.svelte')).default);
    });

    $: webManifest = pwaInfo ? pwaInfo.webManifest.linkTag : '';

    // 아래는 transition animation 효과를 위한 코드
    // https://joshcollinsworth.com/blog/sveltekit-page-transitions
    export let data;

    // TODO: https://github.com/sveltejs/kit/issues/9868
    if (data.savedState) {
        history.replaceState(
            {
                ...data.savedState,
            },
            '',
        );
    }

    $: ({ pathname, savedState } = data);
    const transitionOut = { easing: cubicIn, duration: 10 }; /* out:fade|global={transitionOut} 을 설정하면 svelte의 오류인지 페이지 전환후 기존의 로직들이 중복실행됨 */

    // TODO 로딩 - API 호출시 또는 필요시에만 로딩바가 나도록 변경필요
    let isLoading = false;
    beforeNavigate(async ({ from, to, willUnload, type }) => {
        // if (to?.route.id) {
        //     isLoading = true;
        // }
    });
    afterNavigate(() => {
        // isLoading = false;
    });
</script>

<svelte:head>
    <title>BHSN.AI 메인페이지</title>
    {@html webManifest}
</svelte:head>

<!-- 페이지 전환중 로딩바 -->
{#if isLoading}
    <div class="fixed z-10 flex h-screen w-screen items-center justify-center">
        <div class="flex h-12 w-12 items-center justify-center">
            <Loader />
        </div>
    </div>
{/if}

<!-- 본문 -->
<div class="h-0 min-h-screen w-full min-w-[80rem]" class:opacity-0={!loaded}>
    {#key pathname}
        <div class="h-full">
            <slot />
        </div>
    {/key}
</div>

<!-- global functional modal -->
{#each $modal as { Component, props, modalProps, id, type }, i (id)}
    {#if type === 'fullcustom'}
        <svelte:component this={Component} on:close={e => modal.close(id, e.detail)} on:confirm={e => modal.close(id, e.detail)} on:cancel={() => modal.close(id, false)} {...props} />
    {:else}
        <Modal open {...modalProps} on:outroend={e => modal.close(id, ['confirm', 'prompt', 'custom'].includes(type) ? false : true)} zIndexBackdrop={40 + i * 10} zIndexModal={45 + i * 10}>
            <svelte:component this={Component} on:confirm={e => modal.close(id, e.detail)} on:cancel={() => modal.close(id, false)} {...props} />
        </Modal>
    {/if}
{/each}
<!-- global toast -->
<Toast />

<!-- PWA 리로드 프롬프트 -->
{#if ReloadPrompt}
    <svelte:component this={ReloadPrompt} />
{/if}
