import type { LayoutLoad } from './$types';
import { auth, session } from '$stores/auth';
import { get } from 'svelte/store';

// root의 layout load 는 페이지 이동시 항상 실행이 됨
export const load: LayoutLoad = async ({ url }) => {
    const { pathname } = url;
    return {
        pathname,
        session: get(session),
        // TODO: https://github.com/sveltejs/kit/issues/9868
        savedState: typeof history !== 'undefined' ? history?.state : null,
    };
};

export const prerender = 'auto';
export const ssr = true;
// export const trailingSlash = 'always';
export const trailingSlash = 'ignore';
